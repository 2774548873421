import React from "react"
import PropTypes from "prop-types"

const CheckboxInputindiv = ({ name, value, onChange, placeholder }) => {
    return (
        <div>
            <input id={name} type={"checkbox"} className={"mr-3"} name={name} value={value} checked={value} onChange={e => onChange(e)} />
            <label htmlFor={name} className={"mb-0"}>{placeholder}</label>
        </div>
    )
}

CheckboxInputindiv.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
}

export default CheckboxInputindiv
